<template>
  <div>
    <h2>Reportes</h2>
    <div class="MenuReportes">
      <div class="row">
         <div class="form-group col-md-3 col-11">
          <label for="reporte">Origen</label>
          <select v-model="origen" class="form-control" v-on:change="CambioOrigen" required>
            <option disabled value="" hidden>Seleccione un origen</option>
            <option v-for="item in origenes" :key="item.id" :value="item">{{ item.nombre }}</option>
          </select>
        </div>
        <div class="form-group col-md-3 col-11">
          <label for="fechaInicial">Fecha inicial</label>
          <input v-model="fecha_inicio" type="date" id="fecha_inicio" class="form-control" />
        </div>
        <div class="form-group col-md-3 col-11">
          <label for="fechaFinal">Fecha final</label>
          <input v-model="fecha_final" type="date" id="theDate" class="form-control" />
        </div>
        <div class="form-group col-md-3 col-11">
          <label for="reporte">Reporte</label>
          <select v-model="reporte" class="form-control" v-on:change="signalChange" required>
            <option disabled value="" hidden>Selccione una opcion (Pacientes del mes por asesor)</option>
            <option v-for="item in brands" :key="item.id" :value="item">{{ item.reporte }}</option>
          </select>
        </div>

      </div>

     <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <div class="form-group">
            <button type="button" class="btn btn-primary btn-lg" @click="consultarReporte" :disabled="!status">
              Consultar <span v-if="!status" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
      </div>


    </div>
    <div class="alert alert-info" v-show="datos && datos.length == 0">Sin resultados en este rango de fechas</div>

    <h5 v-show="vsrep1">*Éste reporte solo toma en cuenta la fecha inicial</h5>
    <b v-show="reporte.descripcion">Descripción del reporte:</b>
    <p v-html="reporte.descripcion"></p>
    <h3 v-show="cvshow2">Grafica de pacientes atendidos</h3>
    <Chart type="line" :height="ochenta" :data="basicData" v-show="cvshow2" :options="options" />
    
   <div v-show="vsRepEncuestaDetalle || vsRepEncuesta" class="container-fluid mb-3 encuestas_config">
    <p>Estatus: <span>{{ ConfiguracionEncuestas.configuracion }}</span></p>
    <p>Limite de encuestas: <span>{{ ConfiguracionEncuestas.limite }}</span></p>
    <p>Vigencia: <span>{{ ConfiguracionEncuestas.vigencia }}</span></p>
  </div>



    <div v-show="vsRepEncuestaDetalle" class="container-fluid mb-3">
      <div class="row text-center inline-columns-encabezado">
        <div class="col-4"><div>Total de Encuestas</div></div>
        <div class="col-4"><div>CSAT PROMEDIO</div></div>
        <div class="col-4"><div>CSAT PORCENTAJE</div></div>
        <!--div><div>Total detractores</div></div>
        <div><div>Total pasivos</div></div>
        <div><div>Total promotores</div></div>
        <div><div>Porcentaje promotorores</div></div>
        <div><div>Porcentaje detractores</div></div>
        <div><div>Indicador neto promotores</div></div-->
      </div>

      <div class="row text-center inline-columns-resultado">
        <div class="col-4"><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.total_pacientes : '' }}</div></div>
        <div class="col-4"><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.csat_promedio : '' }}</div></div>
        <div class="col-4"> <div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.csat_porcentaje : '' }}</div></div>
        <!--div><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.total_detractores : '' }}</div></div>
        <div><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.total_pasivos : '' }}</div></div>
        <div><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.total_promotores : '' }}</div></div>
        <div><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.porcentaje_promotores : '' }}</div></div>
        <div><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.porcentaje_detractores : '' }}</div></div>
        <div><div>{{ valorEncuestaDetalle ? valorEncuestaDetalle.indicador_neto_promotores : '' }}</div></div-->
    </div>

    </div>

    <div v-show="vsrep8" class="container-fluid mb-3">
      <div class="row">
        <ul class="list-group col-3">
          <h5>Asesor:</h5>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Promedio <span
              class="font-weight-bold ml-2">{{ feliz.asesor.promedio }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Contestados <span
              class="font-weight-bold ml-5">{{ feliz.asesor.contestadas }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Enviados <span
              class="font-weight-bold ml-5">{{ feliz.asesor.enviadas }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Total <span
              class="font-weight-bold ml-5">{{ feliz.asesor.total_obtenido }}/{{ feliz.asesor.total_esperado }}</span>
          </li>
          <li v-for="calificacion in feliz.asesor.calificaciones" :key="calificacion.id"
            class="d-inline-block list-group-item d-flex justify-content-end align-items-center"> <small
              class="estrellas" v-for="cal in calificacion.calificacion" :key="cal.id">⭐</small> <span
              class="font-weight-bold ml-5">{{ calificacion.suma }}</span>
          </li>
        </ul>
        <ul class="list-group col-3">
          <h5>Asistente Virtual:</h5>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Promedio <span
              class="font-weight-bold ml-2">{{ feliz.bot.promedio }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Contestados <span
              class="font-weight-bold ml-5">{{ feliz.bot.contestadas }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Enviados <span
              class="font-weight-bold ml-5">{{ feliz.bot.enviadas }}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center"> Total <span
              class="font-weight-bold ml-5">{{ feliz.bot.total_obtenido }}/{{ feliz.bot.total_esperado }}</span>
          </li>
          <li v-for="calificacion in feliz.bot.calificaciones" :key="calificacion.id"
            class="d-inline-block list-group-item d-flex justify-content-end align-items-center"> <small
              class="estrellas" v-for="cal in calificacion.calificacion" :key="cal.id">⭐</small> <span
              class="font-weight-bold ml-5">{{ calificacion.suma }}</span>
          </li>
        </ul>
      </div>
    </div>
    <Chart type="line" :height="ochenta" :data="basicData" v-show="porHora" :options="options" />
    <b class="text-danger h2" v-show="vsrep14">Éste reporte ignora la fecha:</b>
    <GPacientesAtendidos v-if="sesion.grupo_id < 6" v-show="reporte == ''" :height="ochenta" />
    <tabla v-show="reporte != ''" :Treporte="datos" :columnas="tabla" />
    <b class="text-danger h4" v-show="vsrep17">*El promedio de la fecha actual se debe ver en monitor</b>
  </div>
</template>

<style scoped>
.MenuReportes {
  margin-bottom: 1rem
}

.p-autocomplete-dd {
  margin-left: 3rem
}

.p-inputtext {
  width: 180px
}


.inline-columns-encabezado > div {
  display: inline-block;
  width: 14.28%; /* 100% dividido entre 7 */
  text-align: center; /* Centra el texto dentro de cada columna */
  vertical-align: top; /* Alinea los divs en la parte superior */
  font-weight: bold;
  font-size: 14px;
}

.inline-columns-resultado > div {
  display: inline-block;
  width: 14.28%; 
  text-align: center; 
  vertical-align: top;
  font-weight: bold;
  font-size: 18px;
}

.inline-columns-encabezado > div > div{
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
}

.inline-columns-resultado > div > div{
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
}

.encuestas_config p {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.encuestas_config p span {
  font-size: 18px;
  font-weight: bold;
}

</style>

<script>
import controlesServices from "../../services/controles";
import GPacientesAtendidos from '@/components/Dashboard/Graficas/PacientesAtendidos.vue'
import reportesServices from "../../services/reportes"
import Chart from "primevue/chart"
//import Rating from "primevue/rating"
import Tabla from './Tablas/ReporteDinamico'
import { origenesDatos } from "../../utils/origenSelect";
import {
  columnsrep1,
  columnsrenomedicos,
  medicosEncuesta,
  medicosEncuestaDia,
  columnssinatender,
  columnsrep3,
  columnsrep4,
  columnsrep5,
  columnsrep6,
  columnsrep7,
  columnsrep8,
  columnsrep9,
  columnsrep10,
  columnsrep12,
  columnsrep13,
  columnsrep14,
  columnsrep15,
  columnsrep16,
  columnsrep17,
  columnsrep18,
  columnsrep19,
  columnsrep20,
  columnsrep21,
  columnsrep22,
  columnsrep23,
  columnsrep24,
  columnsrep26,
  columnsrep27,
  columnsrep28,
  columnsrep29,
  columnsrep30,
  columnsrep31,
  columnsrep32,
  columnsrep33,
  columnsrep34,
  columnsrep35,
  columnsrep36,
  columnsrep37,
  columnsrepEsperaAsesor,
  columnsrepGoogleCAsesor,
  columnsrepGooglePAsesor,
  columnsComentariosFelizometro,
  columnsEncuestasContestadas,
  columnsEncuestasContestadasDetalle,
  columnsgetEncuesta
  // Importa todas las demás columnas que necesites
} from '../../utils/columnsConfig';

export default {
  name: "Reportes",
  components: {
    Chart: Chart,
    //Rating: Rating,
    Tabla: Tabla,
    'GPacientesAtendidos': GPacientesAtendidos,
  },
  created() {

    let today = new Date()
    this.maxDate = new Date()
    this.maxDate.setDate(today.getDate() - 1)
   
  },
  data() {
    return {
      moment: require("moment"),
      sesion: JSON.parse(sessionStorage.getItem('sesion')),
      status: true,
      fecha_inicio: null,
      ochenta: 80,
      datos: null,
      tabla: [],
      reporte: '',
      brands: null,
      brand: null,
      origen: '',
      origenes: '',
      ConfiguracionEncuestas: '',
      origenesOpciones: [
        { id: 1, nombre: 'Whatsapp Mexico' },
        { id: 2, nombre: 'Whatsapp Hemodialisis' },
        { id: 3, nombre: 'Whatsapp San Salvador' },
        { id: 4, nombre: 'Whatsapp PetCt' },
        { id: 5, nombre: 'Whatsapp Medicos' },
        { id: 6, nombre: 'Whatsapp Guatemala' },
        { id: 7, nombre: 'Whatsapp Nicaragua' },
        { id: 8, nombre: 'Facebook Mexico' },
        { id: 9, nombre: 'Whatsapp Mesa De Ayuda' },
      ],
      origenesDatos: origenesDatos,
      filteredBrands: null,
      columnsrep1: columnsrep1,
      columnsrep2: null,
      columnsrep3: columnsrep3,
      columnsrep4: columnsrep4,
      columnsrep5: columnsrep5,
      columnsrep6: columnsrep6,
      columnsrep7: columnsrep7,
      columnsrep8: columnsrep8,
      columnsrep9: columnsrep9,
      columnsrep10: columnsrep10,
      columnsrep12: columnsrep12,
      columnsrep13: columnsrep13,
      columnsrep14: columnsrep14,
      columnsrep15: columnsrep15,
      columnsrep16: columnsrep16,
      columnsrep17: columnsrep17,
      columnsrep18: columnsrep18,
      columnsrep19: columnsrep19,
      columnsrep20: columnsrep20,
      columnsrep21: columnsrep21,
      columnsrep22: columnsrep22,
      columnsrep23: columnsrep23,
      columnsrep24: columnsrep24,
      columnsrep26: columnsrep26,
      columnsrep27: columnsrep27,
      columnsrep28: columnsrep28,
      columnsrep29: columnsrep29,
      columnsrep30: columnsrep30,
      columnsrep31: columnsrep31,
      columnsrep32: columnsrep32,
      columnsrep33: columnsrep33,
      columnsrep34: columnsrep34,
      columnsrep35: columnsrep35,
      columnsrep36: columnsrep36,
      columnsrep37: columnsrep37,
      columnsrepGoogleCAsesor: columnsrepGoogleCAsesor,
      columnsrepGooglePAsesor: columnsrepGooglePAsesor,
      columnsrenomedicos: columnsrenomedicos,
      medicosEncuesta: medicosEncuesta,
      medicosEncuestaDia: medicosEncuestaDia,
      columnsrepEsperaAsesor: columnsrepEsperaAsesor,
      columnssinatender: columnssinatender,
      columnsComentariosFelizometro: columnsComentariosFelizometro,
      columnsEncuestasContestadas: columnsEncuestasContestadas,
      columnsEncuestasContestadasDetalle: columnsEncuestasContestadasDetalle,
      columnsgetEncuesta: columnsgetEncuesta,
      cols: "",
      datacol: "",
      vsrep1: false,
      vsrep2: false,
      vsrep3: false,
      vsrep4: false,
      vsrep5: false,
      vsrep6: false,
      vsrep7: false,
      vsrep8: false,
      vsrep9: false,
      vsrep10: false,
      vsrep11: false,
      vsrep12: false,
      vsrep13: false,
      vsrep14: false,
      vsrep15: false,
      vsrep16: false,
      vsrep17: false,
      vsrep18: false,
      vsrep19: false,
      vsrep20: false,
      vsrep21: false,
      vsrep22: false,
      vsrep23: false,
      vsrep24: false,
      vsrep25: false,
      vsrep26: false,
      vsrep27: false,
      vsrep28: false,
      vsrep29: false,
      vsrep30: false,
      vsrep31: false,
      vseEspera: false,
      vsComfeliz: false,
      vsRepEncuesta: false,
      vsRepEncuestaDetalle: false,
      vsRepEncuestaPet: false,
      valorEncuestaDetalle: {},
      vsenoMedicos: false,
      cvshow2: false,
      porHora: false,
      valorespro: { respuesta1: 1, respuesta2: 2, respuesta3: 3, respuesta4: 4, respuesta5: 5 },
      feliz: {
        asesor: {
          calificaciones: [
            {
              calificacion: 5,
            },
          ],
        },
        bot: {
          calificaciones: [
            {
              calificacion: 5,
            },
          ],
        },
      },
      felizometroAsesor: null,
      resultados: null,
      horaspico: null,
      atendiendo: null,
      hrAsesor: null,
      monitorHr: null,
      flujos: null,
      noAtendidos: null,
      basicData: "",
      fecha_final: null,
      options: {
        responsive: true,
        hoverMode: "index",
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    this.moment.locale("es");

    var date = new Date()
    var day = date.getDate()
    var month = date.getMonth() + 1
    var year = date.getFullYear()

    if (month < 10) month = "0" + month
    if (day < 10) day = "0" + day

    var today = year + "-" + month + "-" + day
    this.fecha_final = today
    this.fecha_inicio = `${year}-${month}-${"01"}`

 
    if (this.sesion.grupo_id == 6) { // mostrar solo medicos
      this.origenes = this.origenesOpciones.filter(item => item.id == 5);
       if (this.origenes.length > 0) {
        this.origen = this.origenes[0];
        this.CambioOrigen(); 
      } 
    }else if (this.sesion.grupo_id == 9) { // mostrar solo petct
      this.origenes = this.origenesOpciones.filter(item => item.id == 4);
      if (this.origenes.length > 0) {
        this.origen = this.origenes[0];
        this.CambioOrigen(); 
      }  
    }else if (this.sesion.grupo_id == 10) { // mostrar solo mesa de ayuda
      this.origenes = this.origenesOpciones.filter(item => item.id == 9);
      if (this.origenes.length > 0) {
        this.origen = this.origenes[0];
        this.CambioOrigen(); 
      } 
    } else {
      //mostrar todo menos medicos y mesa de ayuda
      this.origenes = this.origenesOpciones.filter(item => item.id != 5 && item.id != 9);

    }



  },
  methods: {
      obtenerControles() {
      //obtener los controles y el valor de de la vigencia actal en las encuestas
      controlesServices.Control().then((resp) => {
        const found = resp.find(element => element.id == 53);
          let config = ''
        if (found) {
          const control = found.config;
          if (control.configuracion == 1) { 
            config = 'Diaria'
          }
          else if (control.configuracion == 2) { 
            config = 'Semanal'
          }
          else if (control.configuracion == 3) { 
            config = 'Mensual'
          }
            
          this.ConfiguracionEncuestas = {
            configuracion: config,
            vigencia: control.vigencia_dias + " días",
            limite: control.tope_encuestas
          }
            
          } else {
            console.log("No se encontró el control con id 53.");
          }
        })
        .catch((error) => {
          console.error("Error al obtener controles:", error);
        });  
      },

      async manejarReporte(columnas, servicio) {
      this.tabla = columnas;
      try {
        const datos = await servicio(this.fecha_inicio, this.fecha_final);
        const datosResult = datos.map(x => {
          x.fecha = this.moment(x.createdAt).format("YYYY MMMM DD, dddd");
          return x;
        });
        this.datos = datosResult;
        this.status = true;
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(e) {
    alert('Reintentar en 1 min. ' + e + '.\r\nAl 3er intento favor de reportarlo a Plataforma y Soporte')
        this.$gtag.event('errores', {
          'event_category': 'reportes',
          'event_label': e,
          'value': 500
        })
    this.status = true;
    },
    searchBrand(event) {
      setTimeout(() => {
        let results

        if (event.query.length === 0) {
          results = [...this.brands]
        } else {
          results = this.brands.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }

        this.filteredBrands = results
      }, 250)
    },
    consultarReporte() {
      this.status = false
      console.log(this.sesion.grupo_id,this.reporte.id)
      if (this.sesion.grupo_id == 6 && this.reporte.id == 5) {
        this.tabla = this.columnsrenomedicos
        reportesServices.noMedicos(this.fecha_inicio, this.fecha_final).then((datos) => {
          this.datos = datos
          this.status = true
       }).catch(e => this.handleError(e))

        return
      }
      if (this.sesion.grupo_id == 6 && this.reporte.id == 7) {
        this.tabla = this.medicosEncuesta;
        this.datos = [];

        reportesServices.medicoEncuenta(this.fecha_inicio, this.fecha_final)
          .then((datos) => {
            let pregunta = datos.encuesta.pregunta;
            datos.estadisticas.map((x, index) => {
              datos.estadisticas[index].pregunta = pregunta;
              const prom_asemed = (
                (parseInt(x.respuesta1) +
                  2 * parseInt(x.respuesta2) +
                  3 * parseInt(x.respuesta3) +
                  4 * parseInt(x.respuesta4) +
                  5 * parseInt(x.respuesta5)) /
                (parseInt(x.respuesta1) +
                  parseInt(x.respuesta2) +
                  parseInt(x.respuesta3) +
                  parseInt(x.respuesta4) +
                  parseInt(x.respuesta5))
              );
              datos.estadisticas[index].prom_asemed = parseFloat(prom_asemed.toFixed(2));
            });
            this.datos = datos.estadisticas;
            return reportesServices.medicoEncuenta2(this.fecha_inicio, this.fecha_final);
          })
          .then((datos) => {
            let pregunta = datos.encuesta.pregunta;
            datos.estadisticas.map((x, index) => {
              datos.estadisticas[index].pregunta = pregunta;
              const prom_asemed = (
                (parseInt(x.respuesta1) +
                  2 * parseInt(x.respuesta2) +
                  3 * parseInt(x.respuesta3) +
                  4 * parseInt(x.respuesta4) +
                  5 * parseInt(x.respuesta5)) /
                (parseInt(x.respuesta1) +
                  parseInt(x.respuesta2) +
                  parseInt(x.respuesta3) +
                  parseInt(x.respuesta4) +
                  parseInt(x.respuesta5))
              );
              datos.estadisticas[index].prom_asemed = parseFloat(prom_asemed.toFixed(2));
            });
            this.datos.push(...datos.estadisticas);
            return reportesServices.medicoEncuenta3(this.fecha_inicio, this.fecha_final);
          })
          .then((datos) => {
            let pregunta = datos.encuesta.pregunta;
            datos.estadisticas.map((x, index) => {
              datos.estadisticas[index].pregunta = pregunta;
              const prom_asemed = (
                (parseInt(x.respuesta1) +
                  2 * parseInt(x.respuesta2) +
                  3 * parseInt(x.respuesta3) +
                  4 * parseInt(x.respuesta4) +
                  5 * parseInt(x.respuesta5)) /
                (parseInt(x.respuesta1) +
                  parseInt(x.respuesta2) +
                  parseInt(x.respuesta3) +
                  parseInt(x.respuesta4) +
                  parseInt(x.respuesta5))
              );
              datos.estadisticas[index].prom_asemed = parseFloat(prom_asemed.toFixed(2));
            });
            this.datos.push(...datos.estadisticas);
            this.status = true;
           }).catch(e => this.handleError(e))

        return {};
      }
      if (this.sesion.grupo_id == 6 && this.reporte.id == 9) {
        this.datos = [];
        this.tabla = this.medicosEncuestaDia;
        reportesServices.medicoEncuentaDia(this.fecha_inicio, this.fecha_final)
          .then((datos) => {
    
            for (let i = 0; i < datos.estadisticas.length; i++) {
              const estadistica = datos.estadisticas[i];
              const asesor = estadistica.asesor;

              for (let j = 0; j < estadistica.fechas.length; j++) {
                const fechaCompleta = estadistica.fechas[j].fecha;
                const fecha = fechaCompleta.slice(0, 10);
                const dato = {
                  pregunta: datos.encuesta.pregunta,
                  asesor: asesor,
                  fecha: fecha,
                  respuesta1: parseInt(estadistica.fechas[j].respuesta1),
                  respuesta2: parseInt(estadistica.fechas[j].respuesta2),
                  respuesta3: parseInt(estadistica.fechas[j].respuesta3),
                  respuesta4: parseInt(estadistica.fechas[j].respuesta4),
                  respuesta5: parseInt(estadistica.fechas[j].respuesta5),
                  total_cantidad: parseInt(estadistica.fechas[j].total_cantidad)
                };
                const totalRespuestas = dato.respuesta1 + 2 * dato.respuesta2 + 3 * dato.respuesta3 + 4 * dato.respuesta4 + 5 * dato.respuesta5;
                const totalCantidad = dato.total_cantidad;
                dato.prom_asemed = (totalRespuestas / totalCantidad).toFixed(2);

                this.datos.push(dato);
              }
            }
            return reportesServices.medicoEncuentaDia2(this.fecha_inicio, this.fecha_final);
          })
          .then((datos) => {
            for (let i = 0; i < datos.estadisticas.length; i++) {
              const estadistica = datos.estadisticas[i];
              const asesor = estadistica.asesor;

              for (let j = 0; j < estadistica.fechas.length; j++) {
                const fechaCompleta = estadistica.fechas[j].fecha;
                const fecha = fechaCompleta.slice(0, 10);
                const dato = {
                  pregunta: datos.encuesta.pregunta,
                  asesor: asesor,
                  fecha: fecha,
                  respuesta1: parseInt(estadistica.fechas[j].respuesta1),
                  respuesta2: parseInt(estadistica.fechas[j].respuesta2),
                  respuesta3: parseInt(estadistica.fechas[j].respuesta3),
                  respuesta4: parseInt(estadistica.fechas[j].respuesta4),
                  respuesta5: parseInt(estadistica.fechas[j].respuesta5),
                  total_cantidad: parseInt(estadistica.fechas[j].total_cantidad)
                };
                const totalRespuestas = dato.respuesta1 + 2 * dato.respuesta2 + 3 * dato.respuesta3 + 4 * dato.respuesta4 + 5 * dato.respuesta5;
                const totalCantidad = dato.total_cantidad;
                dato.prom_asemed = (totalRespuestas / totalCantidad).toFixed(2);

                this.datos.push(dato);
              }
            }
            return reportesServices.medicoEncuentaDia3(this.fecha_inicio, this.fecha_final);
          })
          .then((datos) => {
            for (let i = 0; i < datos.estadisticas.length; i++) {
              const estadistica = datos.estadisticas[i];
              const asesor = estadistica.asesor;

              for (let j = 0; j < estadistica.fechas.length; j++) {
                const fechaCompleta = estadistica.fechas[j].fecha;
                const fecha = fechaCompleta.slice(0, 10);
                const dato = {
                  pregunta: datos.encuesta.pregunta,
                  asesor: asesor,
                  fecha: fecha,
                  respuesta1: parseInt(estadistica.fechas[j].respuesta1),
                  respuesta2: parseInt(estadistica.fechas[j].respuesta2),
                  respuesta3: parseInt(estadistica.fechas[j].respuesta3),
                  respuesta4: parseInt(estadistica.fechas[j].respuesta4),
                  respuesta5: parseInt(estadistica.fechas[j].respuesta5),
                  total_cantidad: parseInt(estadistica.fechas[j].total_cantidad)
                };
                const totalRespuestas = dato.respuesta1 + 2 * dato.respuesta2 + 3 * dato.respuesta3 + 4 * dato.respuesta4 + 5 * dato.respuesta5;
                const totalCantidad = dato.total_cantidad;
                dato.prom_asemed = (totalRespuestas / totalCantidad).toFixed(2);

                this.datos.push(dato);
              }
            }
            this.status = true;
            }).catch(e => this.handleError(e))

        return {};
      }
      else if (this.sesion.grupo_id == 6 && this.reporte.id == 6) {
        this.tabla = this.columnssinatender
        reportesServices.sinAtender(this.fecha_inicio, this.fecha_final).then((datos) => {
          this.datos = datos
          this.status = true
        }).catch(e => this.handleError(e))
        return
      }
      if (this.fecha_inicio == "" || this.fecha_final == "" || this.reporte.id <= 0) {
        alert("Los datos ingresados no son correctos")
        this.status = true
      } else {
        if (!this.vsRepEncuestaDetalle && !this.vsRepEncuesta && !this.vsComfeliz && !this.vseEspera && !this.vsrep1 && !this.vsrep8 && !this.vsrep9 && !this.vsrep10 && !this.vsrep11 && !this.vsrep12 && !this.vsrep13 && !this.vsrep14 && !this.vsrep15 && !this.vsrep16 && !this.vsrep17 && !this.vsrep18 && !this.vsrep19 && !this.vsrep20 && !this.vsrep21 && !this.vsrep22 && !this.vsrep23 && !this.vsrep24 && !this.vsrep25 && !this.vsrep26 && !this.vsrep27 && !this.vsrep28 && !this.vsrep29 && !this.vsrep30 && !this.vsrep31 && !this.vsRepEncuestaPet) {
          this.cols = ""
          if (this.vsrep2) {
            this.tabla = this.columnsrep2
          } else if (this.vsrep3) {
            this.tabla = this.columnsrep3
          } else if (this.vsrep4) {
            this.tabla = this.columnsrep4
          } else if (this.vsrep5) {
            this.tabla = this.columnsrep5
          } else if (this.vsrep6) {
            this.tabla = this.columnsrep6
          } else if (this.vsrep7) {
            this.tabla = this.columnsrep7
          }
          reportesServices.reporte(this.fecha_inicio, this.fecha_final, this.reporte.id).then((datos) => {

            this.cols = datos
            this.datos = this.cols

            this.cvshow2 = false

            if (this.reporte.id != 3) {
              datos = datos.map((x) => {
                x.createdAt = this.moment(x.createdAt).format(
                  "YYYY MMMM DD, dddd"
                )
                return x
              })
            } else if (this.reporte.id == 3) {
              datos.datos = datos.datos.map((x) => {
                x.createdAt = this.moment(x.createdAt).format(
                  "YYYY MMMM DD, dddd"
                )
                return x
              })
              this.cols = datos.datos
              this.datos = this.cols
              this.cvshow2 = true
              this.basicData = {
                labels: datos.label,
                datasets: [
                  {
                    label: "Pacientes atendidos por asistente virtual",
                    data: datos.data,
                    fill: false,
                    backgroundColor: "#2f4860",
                    borderColor: "#2f4860",
                  }
                ],
              }
            }
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep1) {
          //Atencion diaria
          this.tabla = this.columnsrep1
          reportesServices.monitorHR(this.fecha_inicio, this.fecha_final).then((datos) => {


            datos = datos.map((x) => {
              x.fecha = this.moment(x.date).utc().format("YYYY MMMM DD, dddd")
              if (x.hora == 0) {
                x.hora = '12am'
              } else if (x.hora < 12) {
                x.hora = x.hora + 'am'
              } else if (x.hora == 12) {
                x.hora = '12pm'
              } else if (x.hora > 12) {
                x.hora = x.hora - 12 + 'pm'
              }
              return x
            })
            this.monitorHr = datos
            this.datos = this.monitorHr
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep9) {
          //Horas Pico
          this.tabla = this.columnsrep9
          reportesServices.horaPicoReporte(this.fecha_inicio, this.fecha_final).then((datos) => {
            this.horaspico = datos
            this.datos = this.horaspico
            if (
              this.fecha_final.substr(8, 2) - this.fecha_inicio.substr(8, 2) == 0
            ) {
              this.porHora = true
              let totales = []
              //let completo=[]
              //let fechas=[]
              let horas = []
              //fechas.push(this.horaspico[x].date)
              for (let x = 0; x < this.horaspico.length; x++) {
                totales.push(this.horaspico[x].total)

                if (this.horaspico[x].hora == 0) {
                  horas.push("12am")
                } else if (this.horaspico[x].hora < 12) {
                  horas.push(this.horaspico[x].hora + "am")
                } else if (this.horaspico[x].hora == 12) {
                  horas.push("12pm")
                } else if (this.horaspico[x].hora > 12) {
                  horas.push(this.horaspico[x].hora - 12 + "pm")
                }
              }

              this.basicData = {
                labels: horas,
                datasets: [
                  {
                    label: this.horaspico[0].date,
                    data: totales,
                    fill: false,
                    backgroundColor: "#2f4860",
                    borderColor: "#2f4860",
                  },
                ],
              }
            } else {
              this.porHora = false
            }
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep10 || this.vsrep11) {
          //Resultados

          this.tabla = this.columnsrep10
          if (this.vsrep10) {
            reportesServices.resultadoReporte(this.fecha_inicio, this.fecha_final).then((datos) => {
              datos = datos.map((x) => {
                x.fecha = this.moment(x.fecha).utc().format("YYYY MMMM DD, dddd")
                return x
              })
              this.resultados = datos
              this.datos = this.resultados
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.vsrep11) {
            reportesServices.resultadoReporteSemanal(this.fecha_inicio, this.fecha_final).then((datos) => {

              datos = datos.map((x) => {
                x.fecha = this.moment(x.fecha).utc().format("YYYY MMMM DD, dddd")
                return x
              })
              this.resultados = datos
              this.datos = this.resultados
              this.status = true
            }).catch(e => this.handleError(e))

          }
        } else if (this.vsrep12) {
          //Flujos

          this.tabla = this.columnsrep12
          reportesServices.flujos(this.fecha_inicio, this.fecha_final).then((datos) => {
            datos = datos.map((x) => {
              x.fecha = this.moment(x.fecha).utc().format("YYYY MMMM DD, dddd")
              return x
            })
            this.flujos = datos
            this.datos = this.flujos
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep13) {
          //Hora Asesor
          this.tabla = this.columnsrep13
          reportesServices.horasAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {


            datos = datos.map((x) => {
              x.fecha = this.moment(x.fecha).utc().format("YYYY MMMM DD, dddd")
              x.tiempo_trans = x.tiempo_trans.substr(0, 5) + " hrs"
              x.de = this.moment(x.min)
                .utc()
                .format("HH:mm")
              x.hasta = this.moment(x.max).utc().format("HH:mm")
              return x
            })
            this.hrAsesor = datos
            this.datos = this.hrAsesor
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep14) {
          //#pacientes asesor
          this.tabla = this.columnsrep14
          reportesServices.asesorAtendiendo(this.fecha_inicio, this.fecha_final).then((datos) => {


            datos.map((x) => {
              x.fecha = this.moment(x.fecha).utc().format("YYYY MMMM DD, dddd")

              return x
            })
            this.atendiendo = datos
            this.datos = this.atendiendo
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep15) {
          //atencion por asesor
          this.tabla = this.columnsrep15
          reportesServices.atencionPorAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {


            datos.map((x) => {
              x.fecha = this.moment(x.fecha).utc().format("YYYY MMMM DD, dddd")
              if (x.NOMBRE == null) {
                x.NOMBRE = "TOTAL"
              }
              return x
            })
            this.atendiendo = datos
            this.datos = this.atendiendo
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep16) {
          //Pacientes no atendidos
          this.tabla = this.columnsrep16
          reportesServices.pacientesNoAtendidos(this.fecha_inicio, this.fecha_final).then((datos) => {


            datos = datos.map((x) => {
              x.fecha = this.moment(x.timezone).utc().format("YYYY MMMM DD, dddd")
              return x
            })
            this.noAtendidos = datos
            this.datos = this.noAtendidos
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep17) {
          //atencion promedio
          this.tabla = this.columnsrep17
          reportesServices.atencionAVG(this.fecha_inicio, this.fecha_final).then((datos) => {


            datos = datos.map((x) => {
              x.fecha = this.moment(x.fecha).format("YYYY MMMM DD, dddd")
              x.tiempo = x.time
              if (x.tiempo == null) {
                x.time = 'Sin resultados'
              }
              return x
            })
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep18) {
          //sesiones perdidas
          this.tabla = this.columnsrep18
          reportesServices.sesionesPerdidas(this.fecha_inicio, this.fecha_final).then((datos) => {


            datos = datos.map((x) => {
              x.fecha = this.moment(x.createdAt).utc().format("YYYY MMMM DD, dddd")

              return x
            })
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep19) {//Bitacora de Sesion
          this.tabla = this.columnsrep19
          reportesServices.bitacora('LOGIN', this.fecha_inicio, this.fecha_final).then((datos) => {


            datos = datos.map((x) => {
              x.fecha = this.moment(x.createdAt).tz('America/Mazatlan').format("YYYY-MM-DD")
              x.hora = this.moment(x.createdAt).tz('America/Mazatlan').format("HH:mm")
              x.tipos = x.status == 1 ? 'Inició Sesión' : 'Cerró Sesión'
              return x
            })
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep20) {
          if (this.reporte.id == 2) {
            this.tabla = this.columnsrep22
            reportesServices.medicosPxAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {


              datos = datos.map((x) => {
                x.fecha = this.moment(x.createdAt).format("YYYY MMMM DD, dddd") != 'Fecha inválida' ? this.moment(x.createdAt).format("YYYY MMMM DD, dddd") : 'Promedio'
                return x
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 3) {
            //medicos pacientes totales
            this.manejarReporte(this.columnsrep26, reportesServices.medicosPxporAsesor);

          } else if (this.reporte.id == 4) {
            //medicos pacientes totales
            this.manejarReporte(this.columnsrep24, reportesServices.medicosPacientes);

          } else if (this.reporte.id == 20) {
            //etiquetas
            this.manejarReporte(this.columnsrep20, reportesServices.etiqueta);

          } else if (this.reporte.id == 28) {
            //tiempoMKT
            this.tabla = [
              {
                field: "fecha",
                header: "Fecha",
              },
              {
                field: "promedio_espera_mkt",
                header: "Promedio de Espera (MKT)",
              },
              {
                field: "promedio_espera_real",
                header: "Promedio de Espera (Real)",
              },
              {
                field: "total",
                header: "Total",
              },
            ]
            reportesServices.tiemposMkt(this.fecha_inicio, this.fecha_final).then((datos) => {


              datos = datos.map((x) => {
                x.fecha = this.moment(x.fecha).format("YYYY MMMM DD, dddd") != 'Fecha inválida' ? this.moment(x.fecha).format("YYYY MMMM DD, dddd") : 'Promedio'
                x.promedio_espera_mkt = this.moment(x.promedio_espera_mkt, "hhmmss").format("HH:mm")
                x.promedio_espera_real = this.moment(x.promedio_espera_real, "hhmmss").format("HH:mm")
                return x
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 29) {
            //tiempoPromedioEsperaDia
            this.tabla = [
              {
                field: "hora_solicitud",
                header: "Hora",
              },
              {
                field: "promedio_espera_mkt",
                header: "Promedio de Espera (MKT)",
              },
              {
                field: "promedio_espera_real",
                header: "Promedio de Espera (Real)",
              },
              {
                field: "total",
                header: "Total",
              },
            ]
            reportesServices.tiemposDia(this.fecha_inicio, this.fecha_final).then((datos) => {


              datos = datos.map((x) => {
                x.promedio_espera_mkt = this.moment(x.promedio_espera_mkt, "hhmmss").format("HH:mm")
                x.promedio_espera_real = this.moment(x.promedio_espera_real, "hhmmss").format("HH:mm")
                if (x.hora_solicitud == null) x.hora_solicitud = 'Promedio General'
                return x
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 30) {
            //porcentaje de atencion
            this.tabla = [
              {
                field: "solicitud",
                header: "Fecha",
              },
              {
                field: "solicitudesTotales.solicitudes",
                header: "Solicitudes Totales",
              },
              {
                field: "atendidos",
                header: "Solicitudes Atendidas",
              },
              {
                field: "solicitudes",
                header: "Solicitudes No Atendidas",
              },
              {
                field: "sCanceladas.canceladas",
                header: "Solicitudes Canceladas",
              },
              {
                field: "pc",
                header: "Porcentaje de atención",
              },
            ]
            reportesServices.colaAtendida(this.fecha_inicio, this.fecha_final).then((datos) => {


              datos = datos.map((x) => {
                x.solicitud = this.moment(x.solicitud).format("YYYY MMMM DD, dddd")
                x.solicitudes = x.solicitudes - x.atendidos
                return x
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 31) {
            //porcentaje de atencion
            this.tabla = [
              {
                field: "fecha",
                header: "Fecha",
              },
              {
                field: "nombre",
                header: "Asesor",
              },
              {
                field: "tipo",
                header: "Situación",
              },
              {
                field: "tiempo",
                header: "Tiempo",
              },
            ]
            reportesServices.asesoresInactivos(this.fecha_inicio, this.fecha_final).then((datos) => {


              datos = datos.map((x) => {
                x.fecha = this.moment(x.fecha).format("lll")
                return x
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 32) {
            //hemodialisis pacientes totales
            this.manejarReporte(this.columnsrep22, reportesServices.hemodialisisPxAsesor);

          } else if (this.reporte.id == 33) {
            //hemodialisis pacientes totales
            this.manejarReporte(this.columnsrep26, reportesServices.hemodialisisPxporAsesor);

          } else if (this.reporte.id == 34) {
            //hemodialisis pacientes totales
            this.manejarReporte(this.columnsrep24, reportesServices.hemodialisisPacientes);

          } else if (this.reporte.id == 35) {
            //cola por hora
            this.tabla = this.columnsrep27
            reportesServices.cola(this.fecha_inicio, this.fecha_final).then((datos) => {

              datos = datos.map((x) => {
                x.fecha = this.moment(x.fecha).format("YYYY MMMM DD, dddd")
                return x
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 36) {      //Checador-----------
            this.tabla = this.columnsrep28
            reportesServices.getChecador(this.fecha_inicio, this.fecha_final).then((datos) => {


              datos = datos.map((x) => {
                return {
                  fecha: x.fecha,
                  nombre: x.nombre,
                  num_colaborador: x.num_colaborador ? x.num_colaborador : "-",
                  comida_i: x.comida_i ? x.comida_i.slice(11, 19) : "-",
                  comida_f: x.comida_f ? x.comida_f.slice(11, 19) : "-",
                  inicio_sesion: x.inicio_sesion ? x.inicio_sesion.slice(11, 19) : "-",
                  cierre_sesion: x.cierre_sesion ? x.cierre_sesion.slice(11, 19) : "-",
                  primerMensaje: x.primerMensaje ? x.primerMensaje.slice(11, 19) : "-",
                  diferenciaEntrada1: x.diferenciaEntrada1 ? x.diferenciaEntrada1 : "-",
                  diferenciaEntrada2: x.diferenciaEntrada2 ? x.diferenciaEntrada2 : "-",
                  diferenciaSalida1: x.diferenciaSalida1 ? x.diferenciaSalida1 : "-",
                  diferenciaSalida2: x.diferenciaSalida2 ? x.diferenciaSalida2 : "-",
                  entradaEstatus1: x.entradaEstatus1 ? x.entradaEstatus1 : "-",
                  entradaEstatus2: x.entradaEstatus2 ? x.entradaEstatus2 : "-",
                  salidaEstatus1: x.salidaEstatus1 ? x.salidaEstatus1 : "-",
                  salidaEstatus2: x.salidaEstatus2 ? x.salidaEstatus2 : "-",
                  tiempo_nt: x.tiempo_nt,
                  tiempo_t: x.tiempo_t,

                }
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 37) {   //Felizometro Asesor
            this.tabla = this.columnsrep29
            reportesServices.getFelizometroAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 38) {     //Registro Copys-----------
            this.tabla = this.columnsrep30
            reportesServices.CopysEnviados(this.fecha_inicio, this.fecha_final).then((datos) => {
             
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 39) {     //Bitacora de Breaks-----------
            this.tabla = this.columnsrep31
            reportesServices.BitacoraBreaks(this.fecha_inicio, this.fecha_final).then((datos) => {
              
              datos = datos.map((x) => {
                x.desde = x.desde.slice(11, 19)
                if (!x.hasta) {
                  x.hasta = ''
                } else {
                  x.hasta = x.hasta.slice(11, 19)
                }
                return x
              })

              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 40) {     //Comportamiento diario WhatsApp-----------
            this.tabla = this.columnsrep32
            reportesServices.ReporteUnificado(this.fecha_inicio, this.fecha_final).then((datos) => {
              datos = datos.map((x) => {
                if (!x.p_atencion) {
                  x.p_atencion = ''
                }
                if (!x.atencion_promedio_marketing) {
                  x.atencion_promedio_marketing = ''
                }
                x.atencion_promedio_marketing = x.atencion_promedio_marketing.slice(0, 8)
                return x
              })
              this.datos = datos
              this.status = true
            }).catch(e => this.handleError(e))

          } else if (this.reporte.id == 41) {
            //salvador pacientes totales
            this.manejarReporte(this.columnsrep24, reportesServices.salvadorPacientes);

          } else if (this.reporte.id == 42) {
            //salvadorPxAsesor total de pacientes atendidos por c/asesor
            this.manejarReporte(this.columnsrep26, reportesServices.salvadorPxporAsesor);

          } else if (this.reporte.id == 43) {
            //salvador pacientes en asesor
            this.manejarReporte(this.columnsrep22, reportesServices.salvadorPxAsesor);

          } else if (this.reporte.id == 44) {
            //PetCTPxAsesor total de pacientes atendidos por c/asesor
            this.manejarReporte(this.columnsrep33, reportesServices.petctPxAsesor);

          } else if (this.reporte.id == 45) {
            //petctPxporAsesor total de pacientes atendidos por c/asesor
            this.manejarReporte(this.columnsrep34, reportesServices.petctPxporAsesor);

          } else if (this.reporte.id == 46) {
            //petct pacientes totales
            this.manejarReporte(this.columnsrep35, reportesServices.petctPacientes);
          } else if (this.reporte.id == 53) {
            this.manejarReporte(this.columnsrep24, reportesServices.guatemalaPacientes);

          } else if (this.reporte.id == 54) {
            //salvadorPxAsesor total de pacientes atendidos por c/asesor
            this.manejarReporte(this.columnsrep26, reportesServices.guatemalaPxporAsesor);

          } else if (this.reporte.id == 55) {
            //salvador pacientes en asesor
            this.manejarReporte(this.columnsrep22, reportesServices.guatemalaPxAsesor);
          }else if (this.reporte.id == 57) {
            this.manejarReporte(this.columnsrep24, reportesServices.mesaPacientes);

          } else if (this.reporte.id == 58) {
            //salvadorPxAsesor total de pacientes atendidos por c/asesor
            this.manejarReporte(this.columnsrep26, reportesServices.mesaPxporAsesor);

          } else if (this.reporte.id == 59) {
            //salvador pacientes en asesor
            this.manejarReporte(this.columnsrep22, reportesServices.mesaPxAsesor);
          }

        } else if (this.vsrep21) {
          //facebook atendidos
          this.manejarReporte(this.columnsrep21, reportesServices.facebookAtendidos);

        } else if (this.vsRepEncuestaPet) {
            //petct getencuesta
            this.manejarReporte(this.columnsgetEncuesta, reportesServices.getEncuesta);
          } else if (this.vsrep22) {
          //facebook atendidos unicos
          this.manejarReporte(this.columnsrep22, reportesServices.facebookAtendidosU);

        } else if (this.vsrep23) {
          //facebook solicitud asesor
          this.manejarReporte(this.columnsrep23, reportesServices.facebookSolicitudAsesor);

        } else if (this.vsrep24) {
          //facebook pacientes totales
          this.manejarReporte(this.columnsrep24, reportesServices.facebookPacientes);

        } else if (this.vsrep27) {
          //nicaragua pacientes totales
          this.tabla = this.columnsrep22
          reportesServices.nicaraguaPxAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {
            var moment = require("moment")
            moment.locale("es")
            datos = datos.map((x) => {
              x.fecha = moment(x.createdAt).format("YYYY MMMM DD, dddd")

              return x
            })
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsrep26) {
          this.manejarReporte(this.columnsrep26, reportesServices.nicaraguaPxporAsesor);
          //facebook pacientes totales

        } else if (this.vsrep25) {
          //facebook pacientes totales
          this.manejarReporte(this.columnsrep24, reportesServices.nicaraguaPacientes);

        } else if (this.vseEspera) {
          //tiempos por asesor
          this.tabla = this.columnsrepEsperaAsesor
          reportesServices.tiempoEsperaByAsesor(this.fecha_inicio).then((datos) => {
            datos = datos.map((x) => {
              x.fecha = this.moment(x.fecha).format("YYYY MMMM DD, dddd")
              x.promedio_espera_mkt = this.moment(x.promedio_espera_mkt, "hhmmss").format("HH:mm")
              return x
            })
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))

        } else if (this.vsComfeliz) {
           
          // this.manejarReporte(this.columnsComentariosFelizometro, reportesServices.felizometroComentarios);
          this.tabla = this.columnsComentariosFelizometro
          reportesServices.felizometroComentarios(this.fecha_inicio, this.fecha_final).then((datos) => {
            datos.map((x) => {
              x.fecha = this.moment(x.fecha).format("YYYY MMMM DD, dddd")
              return x
            })
            
            this.datos = datos
            this.status = true

            // this.tabla = datos
            // this.datos = this.atendiendo
            // this.status = true

          }).catch(e => this.handleError(e))

        } else if (this.vsRepEncuesta) {

          
          this.tabla = this.columnsEncuestasContestadas
          reportesServices.reporteEncuestasContestadas(this.fecha_inicio, this.fecha_final).then((datos) => {
            datos.map((x) => {
              x.fecha_contestacion = this.moment(x.fecha_contestacion).format("YYYY MMMM DD");
              x.fecha_envio = this.moment(x.fecha_envio).format("YYYY MMMM DD");
              return x;
            });
                    
            this.datos = datos
            this.status = true

            }).catch(e => this.handleError(e))

        } else if (this.vsRepEncuestaDetalle) {
          this.tabla = this.columnsEncuestasContestadasDetalle
          Promise.all([
            reportesServices.reporteEncuestasContestadasCalcuGeneral(this.fecha_inicio, this.fecha_final),
            reportesServices.reporteEncuestasContestadasCalcuDetalle(this.fecha_inicio, this.fecha_final)
          ])
          .then(([datosGeneral, datosDetalle]) => {
            this.valorEncuestaDetalle = datosGeneral[0] || {};    
            this.datos = datosDetalle  
            this.status = true;
          })
          .catch(e => this.handleError(e));
          
        } else if (this.vsrep28) {
          this.tabla = this.columnsrep36
          reportesServices.ingregosAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {
           
           
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))
         
        } else if (this.vsrep29) {
          this.tabla = this.columnsrep37
          reportesServices.asesorFueradeHora(this.fecha_inicio, this.fecha_final).then((datos) => {
           
           
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))
      
        } else if (this.vsrep30) {//Google
          this.tabla = this.columnsrepGoogleCAsesor
          reportesServices.googlePacientesCadaAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {
              
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))
        
        } else if (this.vsrep31) {//Google
          this.tabla = this.columnsrepGooglePAsesor
          reportesServices.googlePacientesEnAsesor(this.fecha_inicio, this.fecha_final).then((datos) => {
           
           
            this.datos = datos
            this.status = true
          }).catch(e => this.handleError(e))
         
        } else {
          //felizometro
          this.tabla = this.columnsrep8
          reportesServices.felizometroReporte(this.fecha_inicio, this.fecha_final).then((datos) => {
            reportesServices.felizometroAsesorReporte(this.fecha_inicio, this.fecha_final).then((dato1) => {
             
             
              dato1.map((x) => {
                x.timezone = this.moment(x.timezone).utc().format("YYYY MMMM DD, dddd")
                x.avg = ((5 * parseInt(x.respuesta5) + 4 * parseInt(x.respuesta4) + 3 * parseInt(x.respuesta3) + 2 * parseInt(x.respuesta2) + 1 * parseInt(x.respuesta1)) / (parseInt(x.respuesta5) + parseInt(x.respuesta4) + parseInt(x.respuesta3) + parseInt(x.respuesta2) + parseInt(x.respuesta1))).toFixed(2)
                return x
              })
              this.felizometroAsesor = dato1
              this.datos = this.felizometroAsesor
              this.status = true
            }).catch(e => this.handleError(e))
          

            this.feliz = datos
          }).catch(e => this.handleError(e))
     
        }
      }

    },
    todoFalso() {
      const props = [
        'cvshow2', 'vsrep1', 'vsrep2', 'vsrep3', 'vsrep4', 'vsrep5', 'vsrep6',
        'vsrep7', 'vsrep8', 'vsrep9', 'vsrep10', 'vsrep11', 'vsrep12', 'vsrep13',
        'vsrep14', 'vsrep15', 'vsrep16', 'vsrep17', 'vsrep18', 'vsrep19',
        'vsrep20', 'vsrep21', 'vsrep22', 'vsrep23', 'vsrep24', 'vsrep25',
        'vsrep26', 'vsrep27', 'vsrep28', 'vsrep29', 'vsrep30', 'vsrep31',
        'porHora', 'vseEspera', 'vsComfeliz', 'vsRepEncuesta', 'vsRepEncuestaDetalle','vsRepEncuestaPet'
      ];

      props.forEach(prop => {
        this[prop] = false;
      });
    },
    signalChange: function () {
      this.cols = ""
      this.valorEncuestaDetalle = {}
      const reportMap = {
        1: "vsrep1",
        2: "vsrep20",
        3: "vsrep20",
        4: "vsrep20",
        5: "vsrep5",
        6: "vsrep6",
        7: "vsrep7",
        8: "vsrep8",
        9: "vsrep9",
        10: "vsrep10",
        11: "vsrep11",
        12: "vsrep12",
        13: "vsrep13",
        14: "vsrep14",
        15: "vsrep15",
        16: "vsrep16",
        17: "vsrep17",
        18: "vsrep18",
        19: "vsrep19",
        20: "vsrep20",
        21: "vsrep21",
        22: "vsrep22",
        23: "vsrep23",
        24: "vsrep24",
        25: "vsrep25",
        26: "vsrep26",
        27: "vsrep27",
        28: "vsrep20",
        29: "vsrep20",
        30: "vsrep20",
        31: "vsrep20",
        32: "vsrep20",
        33: "vsrep20",
        34: "vsrep20",
        35: "vsrep20",
        36: "vsrep20",
        37: "vsrep20",
        38: "vsrep20",
        39: "vsrep20",
        40: "vsrep20",
        41: "vsrep20",
        42: "vsrep20",
        43: "vsrep20",
        44: "vsrep20",
        45: "vsrep20",
        46: "vsrep20",
        47: "vseEspera",
        48: "vsrep28",
        49: "vsrep29",
        50: "vsrep30",
        51: "vsrep31",
        53: "vsrep20",
        54: "vsrep20",
        55: "vsrep20",
        56: "vsComfeliz",
        57: "vsrep20",
        58: "vsrep20",
        59: "vsrep20",
        60: "vsRepEncuesta",
        61: "vsRepEncuestaDetalle",
        62: "vsRepEncuestaPet"
      };

      const reportKey = reportMap[this.reporte.id];
      if (reportKey == "vsRepEncuesta" || reportKey == "vsRepEncuestaDetalle") {
        this.obtenerControles()
      }

      if (reportKey !== undefined) {
        this.todoFalso();
        this[reportKey] = true; 
      }
    },

    CambioOrigen: function () {
      if (this.origen) {
        const arregloDatos = this.origenesDatos.filter(item => item.origen === this.origen.id);
        this.brands = arregloDatos;
      }
    }
  },
}
</script>
