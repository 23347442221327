export const origenesDatos = [
    {
        id: 1,
        reporte: "Atención diaría por hora",
        descripcion: "Cuántos pacientes fueron atendidos cada hora por bot y asesor, así como el total de pacientes atendidos cada hora. También marca cuántos pacientes no fueron atendidos esa hora.",
        origen: 1
    },
    {
        id: 5,
        reporte: "Total de pacientes atendidos por c/asesor",
        descripcion: "Cuántos pacientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 1
    },
    {
        id: 7,
        reporte: "Traspasos por motivo y fecha",
        descripcion: "Total de traspasos por motivo y fecha.",
        origen: 1
    },
    {
        id: 8,
        reporte: "Felizometro",
        descripcion: '- Promedio de calificación de asesor y bot, con total de encuestas enviadas y contestadas, así como cuántos pacientes contestaron qué tipo de calificación. <br>- Lista de calificaciones por asesor y tipo, con total de calificaciones y promedio general. <br>Nota: en "cantidad" se indican las encuestas contestadas por los pacientes, en "total" se anota el total de encuestas enviadas y contestadas.',
        origen: 1
    },
    {
        id: 10,
        reporte: "Consulta de resultados por día",
        descripcion: "Total de consulta de resultados por día del periodo seleccionado, con divisiones por RSV (estudios) y OPC (lentes). Incluye módulo de recordatorios totales, con divisiones por RSV y OPC.",
        origen: 1
    },
    {
        id: 11,
        reporte: "Consulta de resultados semanal",
        descripcion: "Total de consulta de resultados por semana, con divisiones por RSV (estudios) y OPC (lentes). Incluye módulo de recordatorios totales, con divisiones por RSV y OPC.",
        origen: 1
    },
    {
        id: 12,
        reporte: "Frecuencia de flujos por día",
        descripcion: "Total de uso de flujos cada día del periodo seleccionado, dividido por tipo de flujo.",
        origen: 1
    },
    {
        id: 13,
        reporte: "Registro de asesor por horas atendidas y día",
        descripcion: "Total de horas atendidas por cada asesor, con hora de inicio y hora de finalización, dividido por días del periodo seleccionado.",
        origen: 1
    },
    {
        id: 18,
        reporte: "Sesiones perdidas",
        descripcion: "Total de sesiones perdidas por día del periodo seleccionado.",
        origen: 1
    },
    {
        id: 19,
        reporte: "Bitacora de Sesión",
        descripcion: "Indica la hora en que cada asesor inició o cerró sesión por día del periodo seleccionado.",
        origen: 1
    },
    {
        id: 20,
        reporte: "Etiquetas por asesor",
        descripcion: "Total de etiquetas que asignó cada asesor durante el periodo seleccionado.",
        origen: 1
    },
    {
        id: 29,
        reporte: "Tiempos de Espera por Horas",
        descripcion: "Promedio de tiempo de espera por hora en los dias seleccionados",
        origen: 1
    },
    {
        id: 31,
        reporte: "Inactividad de Asesores",
        descripcion: "",
        origen: 1
    },
    {
        id: 35,
        reporte: "Cola por Hr",
        descripcion: "",
        origen: 1
    },
    {
        id: 21,
        reporte: "Facebook | Total de pacientes atendidos por c/asesor",
        descripcion: "Cuántos pacientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 8
    },
    {
        id: 22,
        reporte: "Facebook | Atendidos Unicos",
        descripcion: "",
        origen: 8
    },
    {
        id: 23,
        reporte: "Facebook | Solicitaron asesor",
        descripcion: "",
        origen: 8
    },
    {
        id: 24,
        reporte: "Facebook | Pacientes",
        descripcion: "",
        origen: 8
    },
    {
        id: 25,
        reporte: "WA Nicaragua | Total de pacientes",
        descripcion: "",
        origen: 7
    },
    {
        id: 26,
        reporte: "WA Nicaragua | Total de pacientes atendidos por c/asesor",
        descripcion: "Cuántos pacientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 7
    },
    {
        id: 27,
        reporte: "WA Nicaragua | Pacientes en asesor",
        descripcion: "",
        origen: 7
    },
    {
        id: 32,
        reporte: "WA Hemodialisis | Pacientes en asesor",
        descripcion: "",
        origen: 2
    },
    {
        id: 33,
        reporte: "WA Hemodialisis | Total de pacientes atendidos por c/asesor",
        descripcion: "Cuántos pacientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 2
    },
    {
        id: 34,
        reporte: "WA Hemodialisis | Total de pacientes",
        descripcion: "",
        origen: 2
    },
    {
        id: 36,
        reporte: "Checador",
        descripcion: "Se pueden observar, los inicios y cierres de sesión de los asesores y detalles comparados con sus horas de entrada y salida",
        origen: 1
    },
    {
        id: 37,
        reporte: "Felizometro Asesores",
        descripcion: "Se puede observar el estado de animo de los Asesores",
        origen: 1
    },
    {
        id: 38,
        reporte: "Registro Copys",
        descripcion: "Se puede observar la cantidad de veces que se a mandao un copy",
        origen: 1
    },
    {
        id: 39,
        reporte: "Bitacora de Breaks",
        descripcion: "Se puede observar la cantidad de veces que se fue a Break un asesor",
        origen: 1
    },
    {
        id: 40,
        reporte: "Comportamiento diario WhatsApp",
        descripcion: "Comportamiento diario WhatsApp",
        origen: 1
    },
    {
        id: 41,
        reporte: "WA Salvador | Total de pacientes",
        descripcion: "",
        origen: 3
    },
    {
        id: 42,
        reporte: "WA Salvador | Total de pacientes atendidos por c/asesor",
        descripcion: "Cuántos pacientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 3
    },
    {
        id: 43,
        reporte: "WA Salvador | Pacientes en asesor",
        descripcion: "",
        origen: 3
    },
    {
        id: 44,
        reporte: "PetCT | Pacientes en asesor",
        descripcion: "",
        origen: 4
    },
    {
        id: 45,
        reporte: "PetCT | Total de pacientes atendidos por c/asesor",
        descripcion: "",
        origen: 4
    },
    {
        id: 46,
        reporte: "PetCT | Total de pacientes",
        descripcion: "",
        origen: 4
    },
    {
        id: 62,
        reporte: "PetCT | Encuesta Asesor",
        descripcion: "",
        origen: 4
    },
    {
        id: 47,
        reporte: "Tiempo espera c/asesor",
        descripcion: "Este reporte solo admite la fecha inicial.",
        origen: 1
    },
    {
        id: 48,
        reporte: "Ingreso Asesor",
        descripcion: "",
        origen: 1
    },
    {
        id: 49,
        reporte: "Solicitud asesor fuera de horario",
        descripcion: "",
        origen: 1
    },
    {
        id: 53,
        reporte: "WA Guatemala | Total de pacientes",
        descripcion: "",
        origen: 6
    },
    {
        id: 54,
        reporte: "WA Guatemala | Total de pacientes atendidos por c/asesor",
        descripcion: "Cuántos pacientes fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 6
    },
    {
        id: 55,
        reporte: "WA Guatemala | Pacientes en asesor",
        descripcion: "",
        origen: 6
    },
    {
        id: 56,
        reporte: "Comentarios felizometro",
        descripcion: "",
        origen: 1
    },
    {     
        id: 60,
        reporte: "Encuestas Contestadas",
        descripcion: "",
        origen: 1
    },
    {
        id: 61,
        reporte: "Reporte de satisfacción",
        descripcion: "",
        origen: 1
    },

    //MEDICOS
    {
        id: 2,
        reporte: "WA CAM | Medicos en asesor",
        descripcion: "Numero de medicos que fueron atendidos.",
        origen: 5
    },
    {
        id: 3,
        reporte: "WA CAM | Total de medicos atendidos por c/asesor medico",
        descripcion: "Cuántos medicos fueron atendidos por un asesor/medico, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 5
    },
    {
        id: 4,
        reporte: "WA CAM | Total de medicos atendidos",
        descripcion: "Todos los medicos que fueron atendidos por el bot.",
        origen: 5
    },
    {
        id: 5,
        reporte: "WA CAM | Total rechazados",
        descripcion: "Todos las personas que fueron rechazadas por no ser medicos.",
        origen: 5
    },
    {
        id: 6,
        reporte: "WA CAM | Total no atendidos",
        descripcion: "",
        origen: 5
    },
    {
        id: 7,
        reporte: "WA CAM | Encuesta de medicos",
        descripcion: "",
        origen: 5
    },
    {
        id: 9,
        reporte: "WA CAM | Encuesta de medicos por día",
        descripcion: "",
        origen: 5
    },


    //MESA DE AYUDA
    {
        id: 57,
        reporte: "WA Mesa Ayuda | Total de colaboradores",
        descripcion: "",
        origen: 9
    },
    {
        id: 58,
        reporte: "WA Mesa Ayuda | Total de colaboradores atendidos por c/asesor",
        descripcion: "Cuántos colaboradores  fueron atendidos por un asesor, identificado por su nombre, cada día del periodo seleccionado.",
        origen: 9
    },
    {
        id: 59,
        reporte: "WA Mesa Ayuda | Colaboradores en asesor",
        descripcion: "",
        origen: 9
    },
]